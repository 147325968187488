import { gql } from "@apollo/client";

export default gql`
	query ($country: String!, $zipCode: String!) {
		addressSearch(
			addressSearchRequest: {
				searchCountry: $country
				searchZipCode: $zipCode
				orderIdentifier: ""
			}
		) {
			state
			city
		}
	}
`;
