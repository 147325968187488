import { useLazyQuery } from "@apollo/client";
import {
	DSBreakpoints,
	DSPalette,
	DSShadows,
	DSTypography
} from "@clickbank-ui/seller-design-system";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import React, { memo, useContext, useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { ORDER_BY_RECEIPT } from "../../Api";
import { AppContext } from "../../context";
import { isRefundAuthFailState } from "../../util/orderHelpers";
import getRandomInt from "../../util/randomID";
import CustomerInformation from "./CustomerInformation";
import OrderSummary from "./OrderSummary";
import ProductDescription from "./ProductDescription/ProductDescription";
import RecurringBilling from "./RecurringBilling";
import SellerInformation from "./SellerInformation";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;
const { cbShadows } = DSShadows;
const { fontWeightReg } = DSTypography;

const styles = {
	root: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		margin: ".5rem 0 .75rem",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			margin: "1.5rem 0",

			"& img": {
				marginTop: "10px",
				marginBottom: "auto"
			}
		},
		"& h3.MuiTypography-root, & caption.MuiTypography-root": {
			color: cbNeutral[400]
		},
		"& img": {
			margin: "1rem auto 0"
		}
	},
	accordionButton: {
		marginRight: "-4px",
		marginBottom: ".5rem",
		paddingRight: 0,
		fontSize: "19px",
		fontWeight: "600",

		"&:hover": {
			background: "none"
		}
	},
	orderDetailsPanel: {
		margin: "16px 0 24px",
		padding: "0 8px",
		background: palette.common.white,
		borderTop: `solid 8px ${palette.primary.main}`,
		boxShadow: cbShadows[2],

		[breakpoints.up("md")]: {
			margin: "24px 0",
			padding: "0 40px"
		},
		"& .MuiButtonBase-root": {
			"&.MuiButton-root": {
				// Deviations from DS so the translations will fit
				fontSize: "1rem",
				fontWeight: fontWeightReg
			}
		}
	},
	productDescription: {
		paddingRight: "20px",
		height: "176px",

		"@media (maxWidth:1050px)": {
			height: "150px"
		}
	},
	relatedOrder: {
		borderBottom: `solid 1px ${cbNeutral[300]}`,

		"&:last-of-type": {
			borderBottom: "none"
		}
	},
	relatedOrdersLabel: {
		display: "inline-block",
		marginTop: "1em",
		padding: "0 1em",
		background: cbNeutral[950]
	},
	refundAuthFailure: {
		color: palette.error.main
	}
};
/**
 * Event - Customer Details component
 * @param {object} orderDetailsInfo
 * @param {bool} collapse
 */

const OrderDetails = props => {
	const orderDate = new Date(props.orderDetailsInfo?.orderDate);

	const OrderDetails = ({ collapse = true, showDate = true, orderDetails }) => {
		const [isCollapsed, setIsCollapsed] = useState(collapse);
		const [orderDetailsInfo, setOrderDetailsInfo] = useState(undefined);
		const { orderByReceiptData, updatedOrders } = useContext(AppContext);

		const [getOrderByReceipt, { data: orderData, loading: orderByReceiptLoading }] =
			useLazyQuery(ORDER_BY_RECEIPT);

		useEffect(() => {
			setOrderDetailsInfo(orderDetails);
			// the order is being loaded after a page change in order history and needs to be refetched since it has been updated
			if (updatedOrders && updatedOrders.includes(orderDetails.receiptNo))
				getOrderByReceipt({ variables: { receipt: orderDetails.receiptNo } });
		}, []); // eslint-disable-line react-hooks/exhaustive-deps

		// update order info that is fetched after an order edit
		useEffect(() => {
			orderByReceiptData &&
				orderDetailsInfo &&
				orderByReceiptData.orderByReceiptNo?.receiptNo === orderDetailsInfo.receiptNo &&
				setOrderDetailsInfo(orderByReceiptData.orderByReceiptNo);
		}, [orderByReceiptData, setOrderDetailsInfo, orderDetailsInfo, orderDetails]);

		useEffect(() => {
			!orderByReceiptLoading && orderData && setOrderDetailsInfo(orderData.orderByReceiptNo);
		}, [orderByReceiptLoading, orderData, setOrderDetailsInfo]);

		const handleToggleCollapse = () => {
			setIsCollapsed(!isCollapsed);
		};

		// if there's only one order in the history, any trials won't have ended
		const hasBeenRebilled = orderDetailsInfo?.rebills?.length > 1;

		const getTickets = () => {
			const relatedTickets = [];
			if (!orderDetailsInfo.rebills) {
				orderDetailsInfo.lineItems.forEach(li => {
					li.supportTickets &&
						li.supportTickets.forEach(t => {
							const ticketObject = {
								receipt: orderDetails.receiptNo,
								lineItem: li,
								ticket: t
							};
							relatedTickets.push(ticketObject);
						});
				});
			} else {
				orderDetailsInfo.rebills.forEach(rebill => {
					rebill.lineItems.forEach(li => {
						li.supportTickets &&
							li.supportTickets.forEach(t => {
								const ticketObject = {
									receipt: rebill.receiptNo,
									lineItem: li,
									ticket: t
								};
								relatedTickets.push(ticketObject);
							});
					});
				});
			}
			return relatedTickets.sort(
				(a, b) => new Date(b.ticket.createTime) - new Date(a.ticket.createTime)
			);
		};

		const dateOptions = {
			year: "numeric",
			month: "short",
			day: "numeric"
		};

		return (
			<>
				<Box sx={styles.root}>
					<Typography
						variant="h3"
						as="h2"
						sx={{
							position: "relative" // so it's clickable
						}}
						data-cy="receipt-number"
					>
						<Trans i18nKey="Common.order">Order</Trans> #{orderDetailsInfo?.receiptNo}
					</Typography>
					{showDate && (
						<Typography variant="h6" fontWeight={400} as="p">
							<Trans i18nKey="Common.orderedOn">Ordered on</Trans>:&nbsp;&nbsp;
							{orderDate.toLocaleDateString("en-US", dateOptions)}
						</Typography>
					)}
				</Box>

				{orderDetailsInfo?.lineItems?.map((value, index) => (
					<Box key={index}>
						<ProductDescription
							orderDetailsInfo={orderDetailsInfo}
							lineItem={value}
							currencyCode={orderDetailsInfo.currencyCode}
							receiptNo={orderDetailsInfo.receiptNo}
							hasBeenRebilled={hasBeenRebilled}
							tickets={getTickets()}
						/>
						{orderDetailsInfo.lineItems.length > 1 && (
							<Divider sx={{ height: 24, mb: 3 }} />
						)}
					</Box>
				))}

				<Collapse in={isCollapsed}>
					{isCollapsed ? (
						<>
							<OrderSummary orderDetailsInfo={orderDetailsInfo} />
							<RecurringBilling rebills={orderDetailsInfo?.rebills} />
							<SellerInformation
								lineItems={orderDetailsInfo?.lineItems}
								pitchPage={orderDetailsInfo?.pitchPage}
								receiptNo={orderDetailsInfo?.receiptNo}
							/>
							<CustomerInformation orderDetailsInfo={orderDetailsInfo} />
						</>
					) : null}
				</Collapse>
				<Box display="flex" justifyContent="flex-end">
					<Button
						id={"collapseButton" + getRandomInt()}
						variant="text"
						onClick={handleToggleCollapse}
						disableRipple={true}
						sx={
							!isCollapsed && isRefundAuthFailState(orderDetailsInfo)
								? { ...styles.refundAuthFailure, ...styles.accordionButton }
								: styles.accordionButton
						}
					>
						{isCollapsed ? (
							<Trans i18nKey="OrderDetails.collapseDetails">Collapse Details</Trans>
						) : (
							<Trans i18nKey="OrderDetails.viewDetails">View Details</Trans>
						)}
						{isCollapsed ? <ExpandLess /> : <ExpandMore />}
					</Button>
				</Box>
			</>
		);
	};

	OrderDetails.propTypes = {
		showDate: PropTypes.bool,
		orderDetails: PropTypes.object
	};

	const OrderDetailsMemo = memo(OrderDetails);

	let relatedOrders = props.orderDetailsInfo?.relatedOrders
		? props.orderDetailsInfo?.relatedOrders
		: [];

	const doesRelatedOrdersExist = relatedOrders?.length > 0 && props.showRelatedOrders;

	if (!!props.onlyRelatedOrders && props.onlyRelatedOrders.length > 0)
		return (
			<Box zIndex="tooltip" sx={styles.orderDetailsPanel}>
				<Box sx={styles.borderTop} />
				<Box sx={styles.orderDetails}>
					<Typography sx={styles.relatedOrdersLabel}>
						<Trans i18nKey="OrderDetails.relatedOrders">Related Orders</Trans>
					</Typography>
					{props.onlyRelatedOrders.map((value, index) => (
						<Box key={index} sx={styles.relatedOrder}>
							<OrderDetailsMemo
								collapse={false}
								showDate={false}
								orderDetails={value}
							/>
						</Box>
					))}
				</Box>
			</Box>
		);

	return (
		<Box zIndex="tooltip" sx={styles.orderDetailsPanel}>
			<OrderDetailsMemo collapse={props.collapse} orderDetails={props.orderDetailsInfo} />
			<Typography>{doesRelatedOrdersExist}</Typography>
			{doesRelatedOrdersExist && (
				<div>
					<Typography sx={styles.relatedOrdersLabel}>
						<Trans i18nKey="OrderDetails.relatedOrders">Related Orders</Trans>
					</Typography>
					{Array.from(relatedOrders).map((value, index) => (
						<Box key={index} sx={styles.relatedOrder}>
							<OrderDetails collapse={false} showDate={false} orderDetails={value} />
						</Box>
					))}
				</div>
			)}
		</Box>
	);
};

OrderDetails.propTypes = {
	orderDetailsInfo: PropTypes.object,
	showDetails: PropTypes.bool,
	showRelatedOrders: PropTypes.bool,
	onlyRelatedOrders: PropTypes.arrayOf(PropTypes.object),
	collapse: PropTypes.bool
};

export default memo(OrderDetails);
