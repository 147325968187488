import { useLazyQuery } from "@apollo/client";
import {
	DSBreakpoints,
	DSPalette,
	DSShadows,
	DSTypography
} from "@clickbank-ui/seller-design-system";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { TAX_CODE } from "../../Api";
import { taxName } from "../../constants";
import { currencyFormatter } from "../../util/Currencyhelpers";
import ShippingStatus from "../OrderDetails/ProductDescription/ShippingStatus.js";
import ReceiptFooter from "./ReceiptFooter";
import ReceiptHeading from "./ReceiptHeading";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;
const { cbShadows } = DSShadows;
const { fontWeightSemi } = DSTypography;

const styles = {
	container: {
		marginTop: "24px",
		padding: "60px",
		background: palette.common.white,
		boxShadow: cbShadows[2],

		[breakpoints.down("md")]: {
			padding: "30px"
		},
		"@media print": {
			margin: 0,
			padding: 0,
			breakAfter: "page",
			boxShadow: "none"
		},
		"& *": {
			color: cbNeutral[100]
		},
		"& .MuiTypography-h5": {
			paddingBottom: "16px",
			fontWeight: fontWeightSemi,

			"@media print": {
				fontSize: 16
			}
		},
		"& .MuiTypography-body2": {
			"@media print": {
				fontSize: 14
			}
		}
	},
	imageContainer: {
		"& img, & svg": {
			maxWidth: 160,
			height: "auto",
			padding: "0 0 1rem",
			marginTop: "5px",
			"@media print": {
				marginTop: 0
			},

			[breakpoints.up("sm")]: {
				padding: "0 1rem"
			}
		}
	},
	lineItemDiv: {
		borderBottom: `1px solid ${cbNeutral[900]}`
	},
	lineItemInfoDiv: {
		display: "flex",
		flexDirection: "column",
		padding: "24px 0",

		[breakpoints.up("sm")]: {
			flexDirection: "row"
		}
	},
	lineItemShippingDiv: {
		marginTop: "15px",
		padding: "24px 0",

		"@media print": { display: "none", padding: 0 }
	},
	summaryDiv: {
		padding: "24px",
		paddingLeft: 0,
		borderRight: `1px solid ${cbNeutral[900]}`,
		width: "50%",
		verticalAlign: "top",
		boxSizing: "border-box",
		display: "inline-block",

		[breakpoints.down("md")]: {
			display: "block",
			width: "100%",
			paddingRight: 0,
			borderRight: 0
		}
	},
	summaryLeftCol: {
		textAlign: "left",
		width: "50%",
		display: "inline-block"
	},
	summaryRightCol: {
		textAlign: "right",
		width: "50%",
		display: "inline-block"
	},
	paymentDiv: {
		padding: "24px",
		width: "50%",
		boxSizing: "border-box",
		display: "inline-block",

		[breakpoints.down("md")]: {
			display: "block",
			width: "100%",
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	paymentLeftCol: {
		textAlign: "left",
		width: "33%",
		display: "inline-block"
	},
	paymentRightCol: {
		textAlign: "right",
		width: "67%",
		display: "inline-block"
	},
	taxDiv: {
		borderBottom: `1px solid ${cbNeutral[900]}`,
		padding: "24px 0",
		textAlign: "center"
	},
	customerInfoDiv: {
		display: "flex",
		flexDirection: "column",
		rowGap: "1.5rem",
		boxSizing: "border-box",
		paddingBottom: "24px",
		wordBreak: "break-all",

		[breakpoints.up("md")]: {
			flexDirection: "row",
			justifyContent: "space-between",
			columnGap: "2rem"
		}
	}
};

export default details => {
	const [orderDetails, setOrderDetails] = useState(undefined);
	const [taxCode, setTaxCode] = useState("");

	const orderDate = new Date(orderDetails?.orderDate);
	const formattedOrderDate = orderDate.toLocaleDateString("en-US");

	const isShippable = orderDetails?.lineItems?.some(item => item.shippable === true);
	const couponItem = orderDetails?.lineItems?.filter(item => item.coupon !== null)[0];
	const country = orderDetails?.shippingCustomer?.country;
	const taxType = () => {
		if (taxName.gst.includes(country)) return <Trans i18nKey="Receipt.gst">GST</Trans>;
		else if (taxName.vat.includes(country)) return <Trans i18nKey="Receipt.vat">VAT</Trans>;
		return <Trans i18nKey="Common.taxes">Taxes</Trans>;
	};

	const [getTaxCode, { data: taxCodeData }] = useLazyQuery(TAX_CODE, {
		variables: { country: orderDetails?.shippingCustomer?.country }
	});

	useEffect(() => {
		setOrderDetails(details?.details);
		orderDetails?.shippingCustomer?.country !== "US" && orderDetails?.taxes > 0 && getTaxCode();
	}, [orderDetails, details, getTaxCode]);

	useEffect(() => {
		taxCodeData && setTaxCode(taxCodeData.taxCodeByCountry.taxCode);
	}, [taxCodeData, setTaxCode]);

	const taxNo = orderDetails?.clickBankTaxNumber;

	return (
		<>
			<Box sx={styles.container} zIndex="tooltip">
				{!orderDetails ? (
					<Grid container justifyContent="center" alignItems="center">
						<CircularProgress />
					</Grid>
				) : (
					<>
						<ReceiptHeading
							receipt={orderDetails.receiptNo}
							orderDate={formattedOrderDate}
						/>
						{orderDetails?.lineItems?.map((lineItem, index) => (
							<Box sx={styles.lineItemDiv} key={index}>
								<Grid container direction="row" sx={{ alignItems: "center" }}>
									<Grid item xs={8} sx={styles.lineItemInfoDiv}>
										{lineItem?.imageUrl && (
											<Box sx={styles.imageContainer}>
												<img
													src={lineItem.imageUrl}
													alt={lineItem.imageAltText}
												/>
											</Box>
										)}
										<Box>
											<Typography variant="h3">{lineItem.title}</Typography>
											<Typography variant="h4">
												{currencyFormatter(
													lineItem.subtotal,
													orderDetails.currencyCode
												)}
											</Typography>
										</Box>
									</Grid>
									<Grid item sx={styles.lineItemShippingDiv}>
										{lineItem.shippable && (
											<ShippingStatus
												shipped={!!lineItem?.shippingNotice?.shippedDate}
											/>
										)}
									</Grid>
								</Grid>
							</Box>
						))}

						<Box sx={styles.summaryDiv}>
							<Typography variant="h4">
								<Trans i18nKey="Common.orderSummary">Order Summary</Trans>
							</Typography>
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography variant="body2">
										<Trans i18nKey="Common.subtotal">Subtotal</Trans>:
									</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography variant="body2" sx={{ fontWeight: "600" }}>
										{currencyFormatter(
											orderDetails.subtotal,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
							{isShippable && (
								<div>
									<Box sx={styles.summaryLeftCol}>
										<Typography variant="body2">
											<Trans i18nKey="Common.shippingHandling">
												Shipping & Handling
											</Trans>
											:
										</Typography>
									</Box>
									<Box sx={styles.summaryRightCol}>
										<Typography variant="body2" sx={{ fontWeight: "600" }}>
											{orderDetails?.shipping === 0 ? (
												<Trans i18nKey="Receipt.free">Free</Trans>
											) : (
												currencyFormatter(
													orderDetails?.shipping,
													orderDetails.currencyCode
												)
											)}
										</Typography>
									</Box>
								</div>
							)}
							{couponItem && (
								<div>
									<Box sx={styles.summaryLeftCol}>
										<Typography variant="body2">
											{couponItem?.coupon.code} -{" "}
											<Trans i18nKey="Receipt.couponValue">
												{{
													value: couponItem?.coupon.value
												}}
												% OFF
											</Trans>
										</Typography>
									</Box>
									<Box sx={styles.summaryRightCol}>
										<Typography variant="body2" sx={{ fontWeight: "600" }}>
											{currencyFormatter(
												orderDetails.discounts,
												orderDetails.currencyCode
											)}
										</Typography>
									</Box>
								</div>
							)}
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography variant="body2">{taxType()}:</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography variant="body2" sx={{ fontWeight: "600" }}>
										{currencyFormatter(
											orderDetails.taxes,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
							<Box
								sx={{
									borderBottom: `1px solid ${cbNeutral[900]}`,
									margin: "8px 0px"
								}}
							/>
							<div>
								<Box sx={styles.summaryLeftCol}>
									<Typography
										variant="body2"
										sx={{
											fontWeight: "600"
										}}
									>
										<Trans i18nKey="Common.total">Total</Trans>:
									</Typography>
								</Box>
								<Box sx={styles.summaryRightCol}>
									<Typography
										variant="body2"
										sx={{
											fontWeight: "600"
										}}
									>
										{currencyFormatter(
											orderDetails.total,
											orderDetails.currencyCode
										)}
									</Typography>
								</Box>
							</div>
						</Box>

						<Box sx={styles.paymentDiv}>
							<Typography variant="h4">
								<Trans i18nKey="Receipt.paymentInfo">Payment Information</Trans>
							</Typography>
							<Box sx={styles.paymentLeftCol}>
								<Typography variant="body2">
									<Trans i18nKey="Receipt.method">Method</Trans>:
								</Typography>
							</Box>
							<Box sx={styles.paymentRightCol}>
								<Typography variant="body2">
									{["PYPL", "PAYPAL_ADAPTIVE", "PAYPAL_COMMERCE"].includes(
										orderDetails?.paymentMethod
									) ? (
										<Trans i18nKey="Receipt.paypal">PayPal</Trans>
									) : orderDetails.last4 ? (
										<Trans i18nKey="Receipt.card">
											{{
												cardType: orderDetails.paymentMethod
											}}{" "}
											ending in {{ last4: orderDetails?.last4 }}
										</Trans>
									) : (
										orderDetails?.paymentMethod
									)}
								</Typography>
							</Box>
						</Box>

						<Box sx={styles.taxDiv}>
							<Typography variant="body2" sx={{ fontWeight: "700" }}>
								**
								<Trans i18nKey="Receipt.taxWarning">
									Taxes subject to change based on location and rate adjustments.
								</Trans>
								**
							</Typography>
						</Box>

						<div className="no-mouseflow">
							<Typography variant="h4" sx={{ m: "24px 0 12px" }}>
								<Trans i18nKey="Receipt.customerInfo">Customer Information</Trans>
							</Typography>

							<Box sx={styles.customerInfoDiv}>
								<Box
									sx={
										taxNo && isShippable
											? styles.contactDivWithTax
											: styles.contactDiv
									}
								>
									<Typography variant="body2">
										<strong>
											<Trans i18nKey="Receipt.contact">Contact</Trans>
										</strong>
									</Typography>
									<Typography>
										<span data-cy="receipt-customer-email">
											{orderDetails?.billingCustomer?.email}
										</span>
										<br />
										{orderDetails?.billingCustomer?.phone && (
											<span data-cy="receipt-customer-phone">
												{orderDetails?.billingCustomer?.phone}
											</span>
										)}
									</Typography>
								</Box>
								{isShippable && (
									<Box sx={{ ...(taxNo && styles.shippingDivWithTax) }}>
										<Typography variant="body2">
											<strong>
												<Trans i18nKey="Common.shippingAddress">
													Shipping Address
												</Trans>
											</strong>
										</Typography>
										<Typography variant="body2">
											<span data-cy="receipt-shipping-full-name">
												{orderDetails?.shippingCustomer.fullName}
											</span>
											<br />
											<span data-cy="receipt-shipping-address1">
												{orderDetails?.shippingCustomer.address1}
											</span>
											{orderDetails?.shippingCustomer.address2 && (
												<>
													<br />
													<span data-cy="receipt-shipping-address2">
														{orderDetails?.shippingCustomer.address2}
													</span>
												</>
											)}
											<br />
											<span data-cy="receipt-shipping-city">
												{orderDetails?.shippingCustomer.city}
											</span>
											{orderDetails?.shippingCustomer.city &&
												orderDetails?.shippingCustomer.state &&
												","}
											{orderDetails?.shippingCustomer.city && " "}
											{orderDetails?.shippingCustomer.state}
											{orderDetails?.shippingCustomer.state && " "}
											{orderDetails?.shippingCustomer.zip}
										</Typography>
									</Box>
								)}
								{taxNo && (
									<Box sx={isShippable ? styles.taxInfoDiv : styles.shippingDiv}>
										<Typography variant="body2">
											<strong>
												<Trans i18nKey="Receipt.taxInformation.header">
													Tax Information
												</Trans>
											</strong>
										</Typography>
										<Typography data-cy="clickbank-vat-number">
											<Trans i18nKey="Receipt.taxInformation.clickBankVatNo">
												ClickBank VAT #:
											</Trans>{" "}
											{taxNo}
										</Typography>
									</Box>
								)}
							</Box>
							<ReceiptFooter showTaxInfo={taxNo} taxCode={taxCode} />
						</div>
					</>
				)}
			</Box>
		</>
	);
};
